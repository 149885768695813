import * as React from "react"
import Layout from "../components/layout"
import { Link } from 'gatsby'
import "@fontsource/raleway/300.css"
import "@fontsource/raleway/500.css"
import styled from 'styled-components'
import { Helmet } from "react-helmet"
import { StaticImage } from 'gatsby-plugin-image'
import { HighlightText } from '../components/generalstyling'

// styles
const MainBodySection = styled.article`
  max-width: 960px;
  margin: 0 auto 20px;

  > h4 {
    max-width: 920px;
    margin: 30px auto 0px auto;

    @media only screen and (max-width: 920px) {
      padding: 0 20px;
    }
  }

  > div {
    width: calc(50% - 20px);
    display: inline-block;
    background-color: #f8f4ec;
    margin: 30px 10px 0;
    text-align: center;
    padding: 20px 0;

    @media only screen and (max-width: 640px) {
      width: calc(100% - 40px);
      margin: 30px 20px 0 20px;
    }

    a {
      font-weight: 300;
    }

    p {
      color: #b13a04;
      font-size: 14px;
      padding-left: 20px;
      padding-right: 20px;

    }
  }
`;

const HomeButton = styled.button`
    background: #b13a04;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    float: right;
    a {
      color: #fcf8e5;
      padding: 10px;
      display: inline-block;
      font-weight: 300;
    }
`;

const HomePageHeader = styled.div`
  text-align: center;
  background-color: #441600;
  position: relative;
  margin-bottom: 40px;

  &:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    margin-left: -20px;
    bottom: -20px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #441600;
  }

  h1 {
    font-size: 44px;
    color: #fff;
    padding-top: 50px;
    font-weight: 300;
  };

  h2 {
    font-weight: 300;
    font-size: 21px;
    margin-top: 5px;
    color: #f4e8ca;
    padding-bottom: 10px;
  }

  p {
    color: #f4e8ca;
    font-family: raleway;
    font-weight: 500;
    font-size: 16px;
    text-shadow: none;
    max-width: 530px;
    margin: 50px auto 0;
    padding-bottom: 50px;

    @media only screen and (max-width: 560px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`;

// markup
const IndexPage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Home | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com" />
          <meta name="description" content="Opera for Everyone. A fun, accessible guide to all things opera." />
        </Helmet>
        <HomePageHeader>
            <h1>The Opera 101</h1>
            <h2>Opera for Everyone</h2>
            <p>Whatever you've been told -- opera isn't hard or intimidating. Here we have only one rule: Enjoy yourself! Opera's a world filled with drama and excitement. We hope you come to love it as much as we do!</p>
        </HomePageHeader>
        <MainBodySection>

          <HighlightText>We created The Opera 101 to provide guides to various <Link to="/operas">operas</Link>, <Link to="/composers">composers</Link> and <Link to="experience">opera houses</Link> to enrich your experience of going to the opera. In practice, the best way to get started is to go see an opera! We know that can be intimidating, so check out <Link to="operaabc/gettingstarted">this brief article</Link> with some reassuring answers to the most common questions (such as do you have to get dressed up).</HighlightText>

          <HighlightText>Once you’ve seen a few operas, the experience can be enhanced by knowing a bit more about the context surrounding them, especially as so many were written in very different historical and global environments than we witness them in today. Opera’s for everyone, and we hope you’ll find exploring the stories and the stories behind the stories as fun as we do!</HighlightText>

          <div>
            <h3><Link to="/operaabc">Opera ABCs</Link></h3>

            <Link to="/operaabc"><StaticImage src="../images/home/history.png" alt="Baroque opera staging"/></Link>

            <p>Don't know your coloratura from your vibrato? What's this Baroque lark anyway? Our opera ABCs will set you straight!</p>

            <HomeButton><Link to="/operaabc">Learn more?</Link></HomeButton>
          </div>
          <div>
            <h3><Link to="/operas">Operas</Link></h3>

            <Link to="/operas"><StaticImage src="../images/home/progress_matt.png" alt="Matthew Rose, the Rake's Progress at Glyndebourne"/></Link>

            <p>Heading to an opera? Get fun facts, synopses, explanations, running times and so much more in our operas section.</p>

            <HomeButton><Link to="/operas">Learn more?</Link></HomeButton>
          </div>

          <div>
            <h3><Link to="/composers">Composers</Link></h3>

            <Link to="/composers"><StaticImage src="../images/home/verdi.png" alt="Giuseppe Verdi sketch"/></Link>

            <p>Who writes operas? Start with our timeline of great composers and then dive into our detailed guides to Puccini and Verdi.</p>

            <HomeButton><Link to="/composers">Learn more?</Link></HomeButton>
          </div>

          <div>
            <h3><Link to="/experience">Experience</Link></h3>

            <Link to="/experience"><StaticImage src="../images/home/bolshoi.png" alt="The Bolshoi Opera House at night"/></Link>

            <p>There's nothing like seeing, hearing and experiencing opera live. Learn about how and where you can do so.</p>

            <HomeButton><Link to="/experience">Learn more?</Link></HomeButton>
          </div>
        </MainBodySection>
    </Layout>
  )
}

export default IndexPage
